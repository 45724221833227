import React from 'react';
//import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
//if component <h1>{this.props.t('lang')}</h1>

function Bio({ t }) {

    return (
        <div className="mainLayout">
            <div className="mainLayOutInner">


                <div className="bioBlock">
                    <h4>{t("Bio")}</h4>

                    <div>
                        {t("BioText")}
                    </div>



                    <h4>{t("Artist statement")}</h4>

                    <div>
                        {t("ArtistStatementText")}
                    </div>


                    <h4>CV</h4>

                    <h4>Lyndon Polan</h4>

                    <div>
                    99 Chabanel Ouest, H2N 1C3, Suite# 217
                    </div>

                    <div>
                    514 754-9856 / l_polan92@hotmail.com
                    </div>

                    <h4>EDUCATION</h4>

                    <div>2017 - 2018 John Molson School of Business, Montreal (Graduate Certificate in Entrepreneurship)</div>
                    <div>2014 - 2017 ESA des Rocailles, Biarritz, CEAP, DNAP (Diplôme National d’Art Plastique)</div>
                    <div>2013 - 2014 Gerrit Rietveld Academie, Amsterdam. Basic year</div>
                    <div>2012 - 2013 Atelier de Sèvres, Paris. Preparatory School</div>
                    <div>2010 - 2012 John Abbott College, Montreal. Fine Arts. DEC</div>


                    <h4>EXHIBITIONS</h4>

                    <div>2020 Atelier Galerie 2112, collective exhibition “Fièvre Hivernale”, curator: Antoine Giasson, Montreal, CA</div>
                    <div>2019 Atelier Galerie 2112, collective exhibition “Expo Popup Art Cible: Novembre”, Montreal, CA</div>
                    <div>2019 Pacart Québec, collective exhibition “Soirée RECONNAISSANCE”, Montreal, CA</div>
                    <div>2019 Atelier Galerie 2112, collective exhibition “Expo Popup Art Cible: Octobre”, Montreal, CA</div>
                    <div>2018 Eastern Bloc, collective exhibition “What the Pop!”, curator: Marie-Michèle Fillion, Montreal, CA</div>
                    <div>2018 Stewart Hall Art Gallery, collective exhibition, permanent collection, Montreal, CA</div>
                    <div>2017 La Cité des Arts, collective exhibition “Bruit de fond/White Noise”, curator: Marie Cantos, Bayonne, FR</div>
                    <div>2017 Blanchisserie Berrogain, installation, exhibition “New Data”, curator: Barthélémy Toguo, Biarritz, FR</div>
                    <div>2016 Water tower, performance, video installation, festival “Bandits-Mages”, Bourges, FR</div>
                    <div>2016 Blanchisserie Berrogain, video installation, exhibition “Des Enchantés” curator: Natacha Nisic, Biarritz, FR</div>
                    <div>2016 Ville de Bayonne Public poster campaign, curator: Didier Arnaudet, Bayonne, FR</div>
                    <div>2016 Luna Negra Café-Théâtre, performance, “Ainsi font font font”, Bayonne, FR</div>
                    <div>2015 Blanchisserie Berrogain, installation “La chambre des merveilles”, curator: Philippe Mayaux, Biarritz, FR</div>
                    <div>2015 Blanchisserie Berrogain, video installation, “L’insularité”, Biarritz, FR</div>
                    <div>2015 Quartier des Rocailles, performance, “Lumières à tous les étages”, Biarritz, FR</div>
                    <div>2015 La Petite Escalère, land art installation, “Le jardin des Rocailles”, curator: Denis Darzacq, Urt, FR</div>
                    <div>2014 Blanchisserie Berrogain, video installation, “États de corps”, with BTS Audiovisuel, Biarritz, FR</div>
                    <div>2014 Cinéma Le Royal, projection of the film “Arrêt 5 minutes”, shot with Nora Martirosyan, Biarritz, FR</div>
                    <div>2014 Projection of “Once Upon a Time” in the School of Creative Media’s 360° Gallery, Hong Kong</div>
                    <div>2014 Gerrit Rietveld Academie, exhibition “Fashion show by the Basic Year”, Amsterdam, NL</div>


                    <h4>PUBLICATIONS</h4>

                    <div>2018 Journal l’étiquette #2, Mille Putois in collaboration with La Brasserie Dunham</div>
                    <div>2017 Journal New Data, Barthélémy Toguo</div>
                    <div>2016 De vraies-fausses affiches, Véronique Fourcade, Journal Sud-Ouest du 06/05/2016</div>
                    <div>2014 Magazine Contemporary Clues for Tomorrow's Trends, Matthias Kreutzer</div>


                    <h4>WORKSHOPS AND INTERNSHIPS</h4>

                    <div>2017 Internship, gallery Espacio Mexico (cultural institute of Mexico) with artist Manuel Rocha, Montreal, CA</div>
                    <div>2017 Workshop with the artist Barthélémy Toguo, Biarritz, FR</div>
                    <div>2016 Internship at the video production agency Romeo et Fils, Montreal, CA</div>
                    <div>2016 Workshop with the artist Natacha Nisic, Biarritz, FR</div>
                    <div>2015 Workshop with the artist Phillip Mayaux, Biarritz, FR</div>
                    <div>2015 Workshop with the artist Denis Darzacq, Biarritz, FR</div>
                    <div>2014 Workshop with the film director Nora Martirosyan, Biarritz, FR</div>

                    <br/>
                    <br/>
                    <br/>

                </div>
                



            </div>
        </div>
    );
}


export default withNamespaces()(Bio);