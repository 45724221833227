import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
//if component <h1>{this.props.t('lang')}</h1>

function Pic17({ t }) {

    return (
        <div className="mainLayout">
            <div className="mainLayOutInner">
                <br />

                <div className="imageWraper">
                <img src="/images/17.jpg" className="pic1M" alt="pic1" />

                <div className="pic1MDisc">
                    <div>Lyndon Polan, Best Served Cold, Huile sur toile, 60x40, 2019</div>
                </div>
                <span className="arrow1"> <Link to="/16">{"<"}</Link> </span>
                </div>

                





            </div>
        </div>
    );
}


export default withNamespaces()(Pic17);