import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//import 'normalize.css/normalize.css';
import './styles/styles.css';
import  Works  from './components/Works';
import  Bio  from './components/Bio';
import  Contact  from './components/Contact';
import  Pic1  from './components/Pic1';
import  Pic2  from './components/Pic2';
import  Pic3  from './components/Pic3';
import  Pic4  from './components/Pic4';
import  Pic5  from './components/Pic5';
import  Pic6  from './components/Pic6';
import  Pic7  from './components/Pic7';
import  Pic8  from './components/Pic8';
import  Pic9  from './components/Pic9';
import  Pic10  from './components/Pic10';
import  Pic11  from './components/Pic11';
import  Pic12  from './components/Pic12';
import  Pic13  from './components/Pic13';
import  Pic14  from './components/Pic14';
import  Pic15  from './components/Pic15';
import  Pic16  from './components/Pic16';
import  Pic17  from './components/Pic17';
import { NotFoundPage } from './components/NotFoundPage';
import Header from './Header';
import './i18n';

ReactDOM.render(
  <BrowserRouter>
  <div>

    <Header />

    <Switch>
      <Route path="/" component={Works} exact={true} />
      <Route path="/bio" component={Bio} />
      <Route path="/contact" component={Contact} />
      <Route path="/1" component={Pic1} />
      <Route path="/2" component={Pic2} />
      <Route path="/3" component={Pic3} />
      <Route path="/4" component={Pic4} />
      <Route path="/5" component={Pic5} />
      <Route path="/6" component={Pic6} />
      <Route path="/7" component={Pic7} />
      <Route path="/8" component={Pic8} />
      <Route path="/9" component={Pic9} />
      <Route path="/10" component={Pic10} />
      <Route path="/11" component={Pic11} />
      <Route path="/12" component={Pic12} />
      <Route path="/13" component={Pic13} />
      <Route path="/14" component={Pic14} />
      <Route path="/15" component={Pic15} />
      <Route path="/16" component={Pic16} />
      <Route path="/17" component={Pic17} />
      
      <Route component={NotFoundPage} />

    </Switch>

  </div>
</BrowserRouter>

  , document.getElementById('root'));
