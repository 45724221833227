import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
//if component <h1>{this.props.t('lang')}</h1>

function Pic3({ t }) {

    return (
        <div className="mainLayout">
            <div className="mainLayOutInner">
                <br />

                <div className="imageWraper">
                <img src="/images/3.jpg" className="pic1M" alt="pic1" />

                <div className="pic1MDisc">
                    <div>Lyndon Polan, Roxana & Alexander, 30x48, Oil on canvas, 2020</div>
                </div>
                <span className="arrow1"> <Link to="/2">{"<"}</Link> </span><span className="arrow2"> <Link to="/4">{">"}</Link></span>
                </div>

                





            </div>
        </div>
    );
}


export default withNamespaces()(Pic3);