import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
//if component <h1>{this.props.t('lang')}</h1>

function Pic1({ t }) {

    return (
        <div className="mainLayout">
            <div className="mainLayOutInner">
                <br />

                <div className="imageWraper">
                <img src="/images/6.jpg" className="pic1M" alt="pic1" />

                <div className="pic1MDisc">
                    <div>Lyndon Polan, fire starter, Huile sur toile, 60x40, 2020 <span className="soldLabel">Sold</span></div>
                </div>
                <span className="arrow1"> <Link to="/5">{"<"}</Link> </span><span className="arrow2"> <Link to="/7">{">"}</Link></span>
                </div>

            </div>
        </div>
    );
}


export default withNamespaces()(Pic1);