import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
//if component <h1>{this.props.t('lang')}</h1>

function Works({ t }) {

    return (
        <div className="mainLayout">
            <div className="mainLayOutInner">
                <br />

                <div className="row">

              
                        <div class="column">
                            <Link to="/1">
                                <span className="pic1">
                                    <img src="/images/mini/1m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Untitled, Oil on wood panel 20x16, 2020</div>
                                </span>
                            </Link>
                       
                
                            <Link to="/5">
                                <span className="pic1">
                                    <img src="/images/mini/5m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, leanin' 2, Huile sur panneau de bois, 39x30, 2020</div>
                                </span>
                            </Link>

                            <Link to="/9">
                                <span className="pic1">
                                    <img src="/images/mini/9m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Untitled2, Huile sur panneau de bois, 11.5x11.5, 2020</div>
                                </span>
                            </Link>

                            <Link to="/14">
                                <span className="pic1">
                                    <img src="/images/mini/14m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan Octo.png, Huile sur toile, 40x60, 2019</div>
                                </span>
                            </Link>

                            <Link to="/17">
                                <span className="pic1">
                                    <img src="/images/mini/17m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Best Served Cold, Huile sur toile, 60x40, 2019</div>
                                </span>
                            </Link>

                        </div>


                            <div class="column">


                            <Link to="/2">
                                <span className="pic1">
                                    <img src="/images/mini/2m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Aphrodite & Apollo, 30x60, Oil on canvas, 2020</div>
                                </span>
                            </Link>


                  
                            <Link to="/6">
                                <span className="pic1">
                                    <img src="/images/mini/6m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, fire starter, Huile sur toile, 60x40, 2020 <span className="soldLabel">Sold</span></div>
                                </span>
                            </Link>

               
                 
                            <Link to="/10">
                                <span className="pic1">
                                    <img src="/images/mini/10m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Sunburn, Huile sur panneau de bois, 16x16, 2020</div>
                                </span>
                            </Link>

               
                 
                            <Link to="/15">
                                <span className="pic1">
                                    <img src="/images/mini/15m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Painting, Huile sur toile, 48x36, 2019</div>
                                </span>
                            </Link>

                            </div>
                  




                            <div class="column">


                            <Link to="/3">
                                <span className="pic1">
                                    <img src="/images/mini/3m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Roxana & Alexander, 30x48, Oil on canvas, 2020</div>
                                </span>
                            </Link>


                            <Link to="/7">
                                <span className="pic1">
                                    <img src="/images/mini/7m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, this is fine, Huile sur toile, 60x40, 2020 <span className="soldLabel">Sold</span></div>
                                </span>
                            </Link>

                 
                            <Link to="/11">
                                <span className="pic1">
                                    <img src="/images/mini/11m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, untitled, Huile sur panneau de bois, 16x12, 2020</div>
                                </span>
                            </Link>

                            <Link to="/12">
                                <span className="pic1">
                                    <img src="/images/mini/12m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, the race, Huile sur panneau de bois, 36x24, 2018</div>
                                </span>
                            </Link>
                   


                            </div>





                            <div class="column">

                            <Link to="/4">
                                <span className="pic1">
                                    <img src="/images/mini/4m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, leanin’, Huile sur panneau de bois, 39x30, 2020</div>
                                </span>
                            </Link>

                            <Link to="/8">
                                <span className="pic1">
                                    <img src="/images/mini/8m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, Untitled, Huile sur panneau de bois, 11.5x11.5, 2020</div>
                                </span>
                            </Link>

                           

                
                            <Link to="/13">
                                <span className="pic1">
                                    <img src="/images/mini/13m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, sexy burka, Huile sur toile, 50x32, 2018</div>
                                </span>
                            </Link>


                            <Link to="/16">
                                <span className="pic1">
                                    <img src="/images/mini/16m.jpg" alt="pic1" />
                                    <div className="picText">Lyndon Polan, quick study, Huile sur panneau de bois, 13x10, 2019</div>
                                </span>
                            </Link>

           
                            
                 
            
                            </div>

           

                </div>







            </div>
        </div>
    );
}


export default withNamespaces()(Works);
