import React from 'react';
//import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import emailjs from 'emailjs-com';
//if component <h1>{this.props.t('lang')}</h1>

function Contact({ t }) {

    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_l35wp3h', 'template_fuohyra', e.target, 'user_clKMSzwMOkzTvrrZH7mpJ')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              alert("Can't send the message. Sorry for this inconvenience, please contact me directly : l_polan92@hotmail.com")
          });
          e.target.reset();
      }

    return (
        <div className="mainLayout">
            <div className="mainLayOutInner">

                <form className="contact-form" onSubmit={sendEmail}>

                    <input type="hidden" name="contact_number" />
                    <label>{t("Name")}</label>

                    <input type="text" name="user_name" />
                    <label>Email</label>

                    <input type="email" name="user_email" />
                    <label>Message</label>
                    
                    <textarea name="message" />
                    <input type="submit" value={t("Send")} />

                </form>

            </div>
        </div>
    );
}


export default withNamespaces()(Contact);