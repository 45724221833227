import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
//if component <h1>{this.props.t('lang')}</h1>

function Pic1({ t }) {

    return (
        <div className="mainLayout">
            <div className="mainLayOutInner">
                <br />

                <div className="imageWraper">

                

                <img src="/images/1.jpg" className="pic1M" alt="pic1" />
                

                <div className="pic1MDisc">
                    <div>Lyndon Polan, Untitled, Oil on wood panel 20x16, 2020</div>
                </div>

                <span className="arrow2"> <Link to="/2">{">"}</Link></span>

                </div>

                





            </div>
        </div>
    );
}


export default withNamespaces()(Pic1);